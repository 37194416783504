<template>
  <div id="search-overlay-wrapper">
    <TransitionRoot
      :show="searchStore.showGlobalSearchOverlay"
      as="template"
      @after-leave="searchStore.closeSearchOverlay"
    >
      <Dialog
        as="div"
        class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20"
        :open="searchStore.showGlobalSearchOverlay"
        @close="searchStore.closeSearchOverlay"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </TransitionChild>

        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <Combobox
            as="div"
            class="mx-auto max-w-xl transform overflow-hidden rounded-xl bg-white dark:bg-gray-900 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
            @update:modelValue="onSelect"
          >
            <div class="relative">
              <SearchIcon
                class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <ComboboxInput
                class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 dark:text-gray-400 placeholder-gray-400 focus:ring-0 sm:text-sm"
                placeholder="Search..."
                :disabled="searchStore.searchDisabled"
                @change="searchStore.updateSearchQuery($event.target.value)"
              />
              <ComboboxButton
                class="absolute inset-y-0 right-0 flex items-center pr-2"
                @click="searchStore.closeSearchOverlay()"
              >
                <XCircleIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
              </ComboboxButton>
              <ComboboxButton
                class="absolute inset-y-0 right-0 flex items-center pr-8"
                @click="searchStore.$reset()"
              >
                <XIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
              </ComboboxButton>
            </div>

            <div
              v-if="searchStore.queryTooShort && !searchStore.searchDisabled"
              class="border-t border-gray-100 py-14 px-6 text-center text-sm sm:px-14"
            >
              <GlobeIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
              <p class="mt-4 font-semibold text-gray-900 dark:text-gray-400">
                Search for customers, orders or anything else
              </p>
              <p class="mt-2 text-gray-500">
                Quickly access anything by running a global search.
              </p>
            </div>

            <ComboboxOptions
              v-if="searchStore.totalResults > 0"
              static
              class="max-h-80 scroll-pt-11 scroll-pb-2 space-y-2 overflow-y-auto pb-2"
            >
              <!-- EVENTS -->
              <li v-if="searchStore.totalResultsEvents">
                <h2 class="bg-gray-100 dark:bg-gray-800 py-2.5 px-4 text-xs font-semibold text-gray-900 dark:text-gray-400 flex">
                  <span class="flex-auto">Events</span>

                  <!-- <span
                                        class="ml-3 flex-auto truncate items-end text-xs italic font-thin text-gray-800"
                                            >Found {{ (searchStore.hitsPerPage < searchStore.totalResultsEvents) ? `${searchStore.hitsPerPage} of ${searchStore.totalResultsEvents}` : `` }} {{ searchStore.totalResultsEvents }} results</span>-->

                  <span
                    class="justify-self-end inline-flex items-end px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 dark:bg-gray-900 text-gray-800 dark:text-gray-300"
                  >{{
                    searchStore.totalResultsEvents
                  }} results</span>
                </h2>
                <ul class="mt-2 text-sm text-gray-800">
                  <ComboboxOption
                    v-for="item in searchStore.searchResultEvents"
                    :key="item.id"
                    v-slot="{ active }"
                    :value="item"
                    as="template"
                  >
                    <li
                      :class="['flex cursor-default select-none items-center px-4 py-2', active && 'bg-primary-600 text-white']"
                    >
                      <CalendarIcon
                        :class="['h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400']"
                        aria-hidden="true"
                      />
                      <span v-if="item.event?.title" class="ml-3 flex-auto truncate dark:text-gray-400">{{
                        item.event?.title
                      }}</span>
                      <span class="text-grey dark:text-gray-400 font-light text-xs">{{
                        $dayjs(item.event.startDate).format('DD/MM/YYYY')
                      }} - {{ $dayjs(item.event.endDate).format('DD/MM/YYYY') }}
                      </span>
                    </li>
                  </ComboboxOption>
                </ul>
              </li>

              <!-- CUSTOMERS -->
              <li v-if="searchStore.totalResultsCustomers">
                <h2 class="bg-gray-100 dark:bg-gray-800 py-2.5 px-4 text-xs font-semibold text-gray-900 dark:text-gray-400 flex">
                  <span class="flex-auto">Customers</span>

                  <span
                    class="justify-self-end inline-flex items-end px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 dark:bg-gray-900 text-gray-800 dark:text-gray-300"
                  >{{
                    searchStore.totalResultsCustomers
                  }} results</span>
                </h2>
                <ul class="mt-2 text-sm text-gray-800">
                  <ComboboxOption
                    v-for="item in searchStore.searchResultCustomers"
                    :key="item.id"
                    v-slot="{ active }"
                    :value="item"
                    as="template"
                  >
                    <li
                      :class="['flex cursor-default select-none items-center px-4 py-2', active && 'bg-primary-600 dark:bg-gray-800 text-white']"
                    >
                      <UserIcon
                        :class="['h-6 w-6 flex-none', active ? 'text-white ' : 'text-gray-400']"
                        aria-hidden="true"
                      />
                      <span class="ml-3 flex-auto truncate dark:text-gray-400">{{ item.firstName }} {{ item.lastName
                      }}</span>
                      <span class="text-grey text-grey dark:text-gray-400 font-medium text-xs">{{ item.email
                      }}</span>
                      <span
                        v-if="searchStore.showCustomerPhoneNumbers"
                        class="ml-2 text-grey text-grey font-light text-xs dark:text-gray-400"
                      >{{ item.phone
                      }}</span>
                    </li>
                  </ComboboxOption>
                </ul>
              </li>

              <!-- ORDERS -->
              <li v-if="searchStore.totalResultsOrders">
                <h2 class="bg-gray-100 dark:bg-gray-800 py-2.5 px-4 text-xs font-semibold text-gray-900 dark:text-gray-400 flex">
                  <span class="flex-auto">Orders</span>

                  <span
                    class="justify-self-end inline-flex items-end px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 dark:bg-gray-900 text-gray-800 dark:text-gray-300"
                  >{{
                    searchStore.totalResultsOrders
                  }} results</span>
                </h2>
                <ul class="mt-2 text-sm text-gray-800">
                  <ComboboxOption
                    v-for="item in searchStore.searchResultOrders"
                    :key="item.id"
                    v-slot="{ active }"
                    :value="item"
                    as="template"
                  >
                    <li
                      :class="['flex cursor-default select-none items-center px-4 py-2', active && 'bg-primary-600 text-white']"
                    >
                      <DocumentTextIcon
                        :class="['h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400']"
                        aria-hidden="true"
                      />
                      <span class="ml-3 flex-auto truncate">
                        {{ item.id }}
                        <!-- <span
                                                    v-if="item.meta?.eventSlug"
                                                    class="ml-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 truncate max-w-[70px]"
                                                        >{{ item.meta?.eventSlug }}</span>-->
                        <span
                          v-if="item.meta?.createdTimestamp"
                          class="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-400"
                        >{{
                          $dayjs(item.meta?.createdTimestamp).format('DD/MM/YYYY')
                        }}</span>
                        <span
                          v-if="item.cartData?.totalCartValue && !item.cancelled"
                          class="ml-1 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800"
                        >{{
                          $displayCurrency(item.cartData?.totalCartValue)
                        }}</span>
                        <span
                          v-if="item.cancelled"
                          class="ml-1 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800"
                        >Cancelled</span>
                        <span
                          v-if="!item.valid"
                          class="ml-1 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800"
                        >Invalid</span>

                        <span
                          v-if="item.manual"
                          class="ml-1 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800"
                        >Manual</span>
                      </span>
                      <span class="text-grey">{{ item.customerSnapshot?.firstName }} {{
                        item.customerSnapshot?.lastName
                      }}</span>
                    </li>
                  </ComboboxOption>
                </ul>
              </li>
            </ComboboxOptions>

            <ComboboxOptions
              v-if="searchStore.isCommandMode && searchStore.searchQuery.startsWith('>')"
              static
              class="max-h-96 scroll-py-3 overflow-y-auto p-3"
            >
              <ComboboxOption
                v-for="item in searchStore.commandLinks"
                :key="item.id"
                v-slot="{ active }"
                :value="item"
                as="template"
              >
                <li :class="['flex cursor-default select-none rounded-xl p-3', active && 'bg-gray-100']">
                  <div
                    :class="['flex h-10 w-10 flex-none items-center justify-center rounded-lg', item.color]"
                  >
                    <component :is="item.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <div class="ml-4 flex-auto">
                    <p :class="['text-sm font-medium', active ? 'text-gray-900' : 'text-gray-700']">
                      {{ item.name }}
                    </p>
                    <p :class="['text-sm', active ? 'text-gray-700' : 'text-gray-500']">
                      {{
                        item.description
                      }}
                    </p>
                  </div>
                </li>
              </ComboboxOption>
            </ComboboxOptions>

            <div v-if="searchStore.searchQuery === '?'" class="py-14 px-6 text-center text-sm sm:px-14">
              <SupportIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
              <p class="mt-4 font-semibold text-gray-900">
                Help with searching
              </p>
              <p class="mt-2 text-gray-500">
                Use this tool to quickly search for events, customers, orders
                and projects across our entire platform. You can also use the search modifiers found in
                the footer below to limit the results to just users or orders. Enter a minimum of 3
                characters to start your search.
              </p>
            </div>

            <div v-if="searchStore.searchQuery === '#'" class="py-14 px-6 text-center text-sm sm:px-14">
              <CollectionIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
              <p class="mt-4 font-semibold text-gray-900">
                Your Projects
              </p>
              <p class="mt-2 text-gray-500">
                Your projects will appear here when you create them.
              </p>
            </div>

            <div v-if="searchStore.searchDisabled" class="py-14 px-6 text-center text-sm sm:px-14">
              <EmojiSadIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
              <p class="mt-4 font-semibold text-gray-900">
                Search Unavailable
              </p>
              <p class="mt-2 text-gray-500">
                Sorry, global search is not enabled on your plan. Please
                upgrade to enable this feature.
              </p>
            </div>

            <div
              v-if="searchStore.searchQuery && !searchStore.isCommandMode && searchStore.totalResults === 0"
              class="py-14 px-6 text-center text-sm sm:px-14"
            >
              <ExclamationIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
              <p class="mt-4 font-semibold text-gray-900">
                No results found
              </p>
              <p class="mt-2 text-gray-500">
                We couldn’t find anything with that term. Please try again.
              </p>
            </div>

            <div class="flex flex-wrap items-center bg-gray-50 dark:bg-gray-800 py-2.5 px-4 text-xs text-gray-700 dark:text-gray-400">
              Type
              <kbd
                :class="['mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white dark:bg-gray-900 font-semibold sm:mx-2', searchStore.searchQuery.startsWith('#') ? 'border-primary-600 text-primary-600' : 'border-gray-400 dark:border-gray-700 text-gray-900 dark:text-gray-400']"
              >#</kbd>
              <span class="sm:hidden">for projects,</span>
              <span class="hidden sm:inline">to access projects,</span>
              <kbd
                :class="['mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white dark:bg-gray-900 font-semibold sm:mx-2', searchStore.searchQuery.startsWith('>') ? 'border-primary-600 text-primary-600' : 'border-gray-400 dark:border-gray-700 text-gray-900 dark:text-gray-400']"
              >&gt;</kbd>
              for commands, and
              <kbd
                :class="['mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white dark:bg-gray-900 font-semibold sm:mx-2', searchStore.searchQuery === '?' ? 'border-primary-600 text-primary-600' : 'border-gray-400 dark:border-gray-700 text-gray-900 dark:text-gray-400']"
              >?</kbd>
              for help.
            </div>

            <!-- <div
                        v-if="query !== '' && filteredItems.length === 0"
                        class="border-t border-gray-100 py-14 px-6 text-center text-sm sm:px-14"
                    >
                        <EmojiSadIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                        <p class="mt-4 font-semibold text-gray-900">No results found</p>
                        <p
                            class="mt-2 text-gray-500"
                        >We couldn’t find anything with that term. Please try again.</p>
                                </div>-->
          </Combobox>
        </TransitionChild>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import { SearchIcon } from '@heroicons/vue/solid'
import { EmojiSadIcon, GlobeIcon, UserIcon, DocumentTextIcon, CalendarIcon, SupportIcon, ExclamationIcon, XCircleIcon, XIcon, CollectionIcon } from '@heroicons/vue/outline'
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  ComboboxButton,
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
// Import store
import { useSearchStore } from '~/stores/search'
// Pinia search store
const searchStore = useSearchStore()

// Dayjs for date formatting
const { $dayjs, $displayCurrency } = useNuxtApp()

// Vue Router
const router = useRouter()

// Subscribe to changes to the search store and run the search if
searchStore.$subscribe((mutation: any, state) => {
  // Do nothing if search is loading
  if (state.isLoading) { return console.log('Search is loading') }

  console.log('searchStore changed:', { mutation, state })

  // Trigger search only if the search text has changed.
  // if (mutation?.events?.key === 'searchQuery') {
  //     searchStore.runSearch()
  // }
  if (state?.searchQueryPending) {
    searchStore.runSearch()
  }
})

// Push user to the relevant route when an item is selected.
const onSelect = async (item) => {
  const route = searchStore.getItemRoute(item)
  if (route) {
    console.log('SearchOverlay onSelect - Hiding global search overlay...')
    await searchStore.$patch({ showGlobalSearchOverlay: false })
    await router.push(route)
  } else {
    throw new Error('No route found for search item', item)
  }
}

</script>
